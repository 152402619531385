import React from 'react';
import { useLocation } from 'react-router-dom';
import InjectChatbot from './InjectChatbot';
import SimpleChatPage from './SimpleChatPage';

const ChatbotPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chatbotKey = queryParams.get('chatbotKey');
  const planKey = queryParams.get('planKey');

  return (
    <div>
      {/* <InjectChatbot chatbotKey={chatbotKey} planKey={planKey} /> */}
      <SimpleChatPage chatbotKey={chatbotKey} planKey={planKey}></SimpleChatPage>

    </div>
  );
};

export default ChatbotPage;