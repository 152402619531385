import resetIcon from '../../../../assets/ic-reset-pwd.svg';
import { useEffect, useState } from 'react';
import Loader from '../../../globalShared/Loader/Loader';
import basestyle from "./../baseStyle.module.css";
import ApiService from '../../../../services/Api';
import { toast } from 'react-toastify';
import classes from './ResetPassword.module.css'

import { useParams, useNavigate } from 'react-router-dom';

function ResetPassword() {

    let { token } = useParams();
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState();
    const [isLinkValid, setisLinkValid] = useState();
    const [visible, setVisible] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [email, setemail] = useState();


    const [form, setForm] = useState({
        password: "",
        cpassword: ""
    });

    const [formErrors, setFormErrors] = useState({
        password: null,
        cpassword: null,
    });

    useEffect(() => {
        checkToken();
    }, []);

    const checkToken = async () => {
        if (token) {
            setisLoading(true);
            try {
                const result = await ApiService.resetToken(token);
                setisLinkValid(true);
                setemail(result.data);
                setisLoading(false);
            }

            // ApiService.resetToken(token)
            //     .then((res) => {
            //         const resData = await res.data;
            //         setisLinkValid(true);
            //         setemail(resData);
            //         setisLoading(false);
            //     })
            catch (error) {
                setisLinkValid(false);
                setisLoading(false);
            };
            setIsSuccess(true);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validateForm(form);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const formData = {
                email: email,
                password: form.password
            };

            console.log(formData);

            setisLoading(true);
            ApiService.userResetPassword(formData)
                .then((res) => {
                    setisLoading(false);
                    toast.success(res?.message);
                    navigate('/sign-in');
                })
                .catch((error) => {
                    setisLoading(false);
                    toast.error(error?.message);
                });
        }
    };

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));

        if (formErrors[name] !== undefined) {
            const errorMsg = validateField(name, value, form[name === 'password' ? 'cpassword' : 'password']);
            setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                [name]: errorMsg,
            }));
        }
    };

    const validateField = (name, value, refValue) => {
        let errorMsg = null;
        switch (name) {
            case 'password':
                if (!value) errorMsg = 'Please enter Password.';
                break;
            case 'cpassword':
                if (!value) errorMsg = 'Please enter Confirm Password.';
                else if (refValue && value !== refValue)
                    errorMsg = 'Password and Confirm Password do not match.';
                break;
            default:
                break;
        }
        return errorMsg;
    };

    const validateForm = (form) => {
        const errorObj = {};
        Object.keys(form).forEach((key) => {
            const refValue = key === 'password' ? form.cpassword : key === 'cpassword' ? form.password : null;
            const msg = validateField(key, form[key], refValue);
            if (msg) errorObj[key] = msg;
        });
        return errorObj;
    };


    const viewPass = () => {
        setVisible(!visible);
        setPasswordType(passwordType === "password" ? "text" : "password");
    };

    const viewConfirmPass = () => {
        setVisibleConfirm(!visibleConfirm);
        setConfirmPasswordType(
            confirmPasswordType === "password" ? "text" : "password"
        );
    };


    return (
        <div className={classes["main-container"]}>
            {isSuccess && (
                <>
                    {!isLinkValid && (
                        <div className={classes["sign_up-box-2"]}>
                            <div className={classes["invalid-link-header"]}>Invalid URL</div>
                            <div className={classes["invalid-link-text"]}>The URL you requested is not valid or has expired.</div>
                        </div>
                    )}

                    {isLinkValid && (
                        <div className={classes["sign_up-box-1"]}>
                            <div className={classes['forgot-logo']}>
                                <img src={resetIcon} />
                            </div>

                            <div className={classes.header}>Reset Password</div>

                            <div>
                                <form onSubmit={handleSubmit}>
                                    <div className={classes["form-section"]}>
                                        <div>
                                            <label
                                                htmlFor="exampleInputPassword1"
                                                className={`${classes.label} form-label`}
                                            >
                                                New Password
                                            </label>

                                            <div className={classes["input-with-icon"]}>
                                                <input
                                                    onChange={changeHandler}
                                                    onBlur={changeHandler}
                                                    type={passwordType}
                                                    placeholder="Password"
                                                    name="password"
                                                    value={form.password}
                                                    className="form-control "
                                                    id="exampleInputPassword1"
                                                />
                                                {form.password && (
                                                    <div className={classes["icon-container"]}>
                                                        <span className={classes["eyeicon"]} onClick={viewPass}>
                                                            <i
                                                                className={`fa ${visible ? "fa-eye" : "fa-eye-slash"}`}
                                                            />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>

                                            {formErrors.password && <span className={basestyle.error}>{formErrors.password}</span>}
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="exampleInputConfirmPassword"
                                                className={`${classes.label} form-label`}
                                            >
                                                Confirm Password
                                            </label>
                                            <div className={classes["input-with-icon"]}>
                                                <input
                                                    onChange={changeHandler}
                                                    onBlur={changeHandler}
                                                    type={confirmPasswordType}
                                                    placeholder="Password"
                                                    name="cpassword"
                                                    value={form.cpassword}
                                                    className="form-control"
                                                    id="exampleInputConfirmPassword"
                                                />
                                                {form.cpassword && (
                                                    <div className={classes["icon-container"]}>
                                                        <span
                                                            className={classes["eyeicon"]}
                                                            onClick={viewConfirmPass}
                                                        >
                                                            <i
                                                                className={`fa ${visibleConfirm ? "fa-eye" : "fa-eye-slash"
                                                                    }`}
                                                            />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>

                                            {formErrors.cpassword && <span className={basestyle.error}>{formErrors.cpassword}</span>}
                                        </div>
                                        <div className="mt-2">
                                            <button className={classes["submit-btn"]}>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}</>
            )}
            <div>{isLoading && <Loader />}</div>
        </div>
    )
}

export default ResetPassword;