import React, {useState,useEffect ,useRef} from 'react';
import styled from 'styled-components';
import './ChatBubblepage';
import mainLogo from '../../../../assets/footer-logo.svg'
import sendIcon from '../../../../assets/ic-blue-con.svg'
import yellowsendIcon from '../../../../assets/ic-white-con.svg';
import loderIcon from '../../../../assets/fade-stagger-circles.svg'
import handIcon from '../../../../assets/icon-hand.png'
import ApiService from '../../../../services/Api';
import './ChatBubble.css';

const ChatbotIcon = styled.div`
  width: 60px;
  height: 60px;
  background-color: rgba(0, 90, 190, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;


const ChatBubble = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [startConversion, setStartConversion] = useState(false);
  const [currentIndex, setCurrentIndex] =useState(-1)
  const [isLoading, setIsLoading]=useState(false)
  const divRef = useRef(null);
  const [chatboData, setChatboData]=useState('');
  const [chatboDataShow, setChatboDataShow]=useState(false)
  const [open, setopen]=useState(false)

  useEffect(() => {
    console.log("ChatbotKey = "+props.chatbotKey);
    console.log("PlanKey = "+props.planKey);
    chatbotStatus()
  }, []);

  const chatbotStatus = ()=>{
  ApiService.getChatbotStatus(props.chatbotKey).then(res=>{
    setopen(true)
    setChatboDataShow(true)
    setChatboData(res)
  }).catch(error=>{
    setChatboDataShow(false)
    setChatboData(error)
    setopen(true)
  })
  }
  const start = () => {
  setStartConversion(true)
  }
  const handleIconClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        handleSend()
    }
  };
  const handleSend = () => {
    if (input.trim()) {
      scrollToBottom()
      setMessages([...messages, { sender: 'user', text: input }]);
      setCurrentIndex( messages?.length -0);
     const data={
             chatBotKey: props.chatbotKey,
             query: input
      }
      setIsLoading(true)
      ApiService.chatbotConversation(data).then(res=>{
        scrollToBottom()
      // setCurrentIndex( messages?.length - 1);
      setIsLoading(false)
      setMessages([...messages, { sender: 'user', text: input , aiResponse:'Bot', aiChat:res?.message}]);
      // setCurrentIndex( messages?.length - 1);
     }).catch(error=>{
      scrollToBottom()
      setIsLoading(false)
      setMessages([...messages, { sender: 'user', text: input , aiResponse:'Bot', aiChat:error?.message}]);
     })
      setInput('');
     
    }
  };
  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }

  return (
    <>
      {isOpen && (
        <div className="chat-bot">
          <div className='chatbot-responsive'>
          <div className='inner'>
          <div className="hi-there">
            <div className="hand-icon-position">
              <div>
                <h2 className="yello-color">
                  <span className="yellow-color-text">Hi There </span>
                  <span className="hand-icon"><img src={handIcon} /></span>
                </h2>
                <p className="help-text">Need Help? Start new conversation</p>
              </div>
              <div>
                <img className="robot-img" src={mainLogo} />
              </div>
            </div>
          </div>
          { (chatboDataShow && open) && (
          <div className="content-box-1">
            {!startConversion && (
              <div className="text-center btn-position btn-margin">
                <button onClick={start} className="common_blue-btn icon-hover">
                  <div className="conversation-btn ">
                    <img  src={yellowsendIcon} /> <span>New Conversation</span>
                  </div>

                </button>
              </div>
            )}
            <div className="overflow-chat" ref={divRef}>
              {messages.map((msg, index) => (
                <div>
                  <div key={index}>
                  {msg.sender === 'user' && (
                    <div>
                    <div className=' btn-position text-margin you-div'>
                      <h3 className="yello-color-2 exmaple-text">You</h3>
                      <h4 className="you-exmaple-text-1">{msg.text}</h4>
                    </div>
                      {isLoading && index === currentIndex && (
                        <div className='text-margin Ai-div'>
                          <img className='loader-gpt' src={loderIcon}></img>
                          </div>
                      )}
                      </div>
                  )}
                  {msg.aiResponse === 'Bot' && (
                    <div
                      className="btn-position text-margin Ai-div">
                      <h3 className="yello-color-1 exmaple-text">{chatboData?.details?.chatbot_name}</h3>
                      <h4 className="exmaple-text-1" dangerouslySetInnerHTML={{ __html: msg.aiChat }}></h4>
                    </div>
                  )}
                
               </div>
                </div>

              ))}

              <div className="hidden">bottom</div>
            </div>
            {startConversion && (
              <div className="input-field1" >
                <input onKeyUp={handleKeyPress} name="Message" className='form-control' value={input} onChange={handleInputChange} placeholder="New Message" />
                <img className='img-icon' onClick={handleSend} src={sendIcon} />
              </div>
            )}
            <div className="powered-by">
              <p>Powered by <b>GENX AI</b></p>
            </div>
          </div>
          )}

          {(!chatboDataShow && open) && (
            <div className="content-box-1">
                 <div className="text-center inactive-chat btn-margin">
                 {/* <button onClick={start} className="common_blue-btn icon-hover">
                   <div className="conversation-btn ">
                     <img  src={yellowsendIcon} /> <span>New Conversation</span>
                   </div>
 
                 </button> */}
                 <h4>
                 <p className='opps'>Oops!</p>
                 <p className='text-msg'> {chatboData?.message} Please Contact</p>
                 <p className='text-msg'>with Service Administrative</p>
                 </h4>
               </div>
               </div>
          )}
        </div>
        </div>
        </div>
      )}
      {/* <div class="chatbtnwrapper" id="genx_ai_chat">
        {isOpen && <ChatbotIcon className='icon-hover' onClick={handleIconClick}>X</ChatbotIcon>}

        {!isOpen && <ChatbotIcon className='icon-hover' onClick={handleIconClick}>💬</ChatbotIcon>}
      </div> */}

      {/* <ChatbotWrapper>
      {!isOpen && <ChatbotIcon onClick={handleIconClick}>💬</ChatbotIcon>}
      {isOpen && (
        <ChatWindow>
          <Header onClick={handleIconClick}>Chatbot</Header>
          <ChatArea>
            {messages.map((msg, index) => (
              <div key={index}>{msg.sender === 'user' ? `You: ${msg.text}` : `Bot: ${msg.text}`}</div>
            ))}
          </ChatArea>
          <InputArea>
            <Input value={input} onChange={handleInputChange} />
            <SendButton onClick={handleSend}>Send</SendButton>
          </InputArea>
        </ChatWindow>
      )}
    </ChatbotWrapper> */}
    </>
  );
};

export default ChatBubble;