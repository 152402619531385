import React from "react";
import { Route } from 'react-router-dom';
import UnAuthGuard from '../components/Guards/UnAuthGuard';
import Home from '../components/pages/publicPages/home/Home';
import Login from '../components/pages/common/Login/Login';
import SignUp from '../components/pages/common/Sign-up/SignUp';
import Contact from '../components/pages/common/Contact/Contact';
import Affiliates from "../components/pages/publicPages/home/Affiliates";
import Pricing from "../components/pages/publicPages/home/PricingList/PricingList";
import OurClients from './../components/pages/publicPages/home/OurClients/OurClients';

const UnAuthRoutes = [
    <Route key="Home" path="/" element={<UnAuthGuard component={<Home />} />} />,
    <Route key="Login" path="/sign-in" element={<UnAuthGuard component={<Login />} />} />,
    <Route key="SignUp" path="/signin" element={<UnAuthGuard component={<SignUp />} />} />,
    <Route key="Contact" path="/contact" element={<UnAuthGuard component={<Contact/>} />} />,
    <Route key="Affiliates" path="/affiliates" element={<UnAuthGuard component={<Affiliates />} />} />,
    <Route key="Pricing" path="/pricing" element={<UnAuthGuard component={<Pricing />} />} />,
    <Route key="OurClients" path="/our-clients" element={<UnAuthGuard component={<OurClients />} />} />
]

export default UnAuthRoutes;