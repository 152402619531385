import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import './SimpleChatPage.css';
import Loader from "../../../globalShared/Loader/Loader";
import sendIcon from '../../../../assets/ic-blue-con.svg'
import loderIcon from '../../../../assets/fade-stagger-circles.svg'
import ApiService from '../../../../services/Api';
import SpeakText from '../speak-text/SpeakText';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const ChatbotWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const ChatbotIcon = styled.div`
  width: 60px;
  height: 60px;
  background-color: rgba(0, 90, 190, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ChatWindow = styled.div`
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
`;

const ChatArea = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
`;

const InputArea = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
`;

const SendButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const SimpleChatPage = (props) => {
    const [isShowLoading, setisShowLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [startConversion, setStartConversion] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [isLoading, setIsLoading] = useState(false)
    const divRef = useRef(null);
    const [chatboData, setChatboData] = useState('')
    const [chatboDataShow, setChatboDataShow]=useState(false)
    const [open, setopen]=useState(false);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    useEffect(() => {
        console.log("ChatbotKey = " + props.chatbotKey);
        console.log("PlanKey = " + props.planKey);
        chatbotStatus()
    }, []);

    const chatbotStatus = () => {
        setisShowLoading(true);
        ApiService.getChatbotStatus(props.chatbotKey).then(res => {
            setisShowLoading(false);
            setopen(true)
            setChatboDataShow(true)
            setChatboData(res)
        }).catch(error => {
            setisShowLoading(false);
            setopen(true)
            setChatboDataShow(false)
            setChatboData(error)
        })
    }
    const refresh = ()=>{
        setMessages([]);

    }
    const start = () => {
        setStartConversion(true)
    }
    const handleIconClick = () => {
        setIsOpen(!isOpen);
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };
    const handleKeyPress = (event) => {
        if(transcript){
            setInput(transcript)
            resetTranscript();
        }
        if (event.key === 'Enter') {
            handleSend(input)
        }
      };
    const handleSend = (value) => {
        if(transcript){
            value=transcript;
        }
        SpeechRecognition.abortListening()
        resetTranscript()
        if (value.trim()) {
            scrollToBottom()
            setIsLoading(true)
            setMessages([...messages, { sender: 'user', text: value }]);
            setCurrentIndex(messages?.length - 0);
            const data = {
                chatBotKey: props.chatbotKey,
                query: value
            }
            ApiService.chatbotConversation(data).then(res => {
                scrollToBottom()
                setIsLoading(false)
                setMessages([...messages, { sender: 'user', text: value, aiResponse: 'Bot', aiChat: res?.message }]);
            }).catch(error => {
                scrollToBottom()
                setIsLoading(false)
                setMessages([...messages, { sender: 'user', text: value, aiResponse: 'Bot', aiChat:'Sorry, I am unable to process your request at this moment. Please try again later.' }]);
            })
            setInput('');

        }
    };
    const scrollToBottom = () => {
        if (divRef.current) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
        }
    }
    const stopVoice=(value)=>{
        setInput(value)
    }

    return (
        <>
            <div className={props?.styleChanges?'chat-bot3':'chat-bot1'}>
                {(chatboDataShow && open) && (
                    <div className='inner'>
                        <div className='refresh-Icon'>
                            <span><i onClick={refresh} title='Refresh' class="fa fa-refresh refresh-Icon-pointer" aria-hidden="true"></i></span>
                        </div>
                        <div className="content-box-1">
                            <div className="overflow-chat1" ref={divRef}>
                                <div className='btn-position text-margin Ai-div mag-width'>
                                <h3 className="yello-color-1 exmaple-text">{chatboData?.details?.chatbot_name}</h3>
                                <h4 className="exmaple-text-1">Hi There, How can i help you ?</h4>
                                </div>
                                {messages.map((msg, index) => (
                                    <div>
                                        <div key={index}>
                                            {msg.sender === 'user' && (
                                                <div>
                                                    <div className='msg-position'>
                                                        <div className=' btn-position text-margin you-div'>
                                                            <h3 className="yello-color-2 exmaple-text">You</h3>
                                                            <h4 className="you-exmaple-text-1">{msg.text}</h4>
                                                        </div>
                                                    </div>
                                                    {isLoading && index === currentIndex && (
                                                        <div className='text-margin Ai-div'>
                                                            <img className='loader-gpt' src={loderIcon}></img>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {msg.aiResponse === 'Bot' && (
                                                <div className='mag-width'>
                                                    <div
                                                        className="btn-position text-margin Ai-div">
                                                        <h3 className="yello-color-1 exmaple-text">{chatboData?.details?.chatbot_name}</h3>
                                                        <h4 className="exmaple-text-1" dangerouslySetInnerHTML={{ __html: msg.aiChat }}></h4>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                ))}

                                <div className="hidden">bottom</div>
                            </div>
                        </div>
                        <div className='overlapping-div'>
                            
                                <div className="input-field2" >
                                    <textarea onKeyUp={handleKeyPress} name="Message" className='form-control' value={transcript?transcript:input} onChange={handleInputChange} placeholder="Please enter message" />
                                    <img className='img-icon' onClick={()=>handleSend(input)} src={sendIcon} />
                                    <div>
                                    <SpeakText stop={stopVoice}></SpeakText>
                                    </div>

                                </div>
                        </div>
                        <div className="powered-by-gui">
                                <p>Powered by <b>Genx AI</b></p>
                        </div>
                    </div>
                )}

                {(!chatboDataShow && open) && (
                    <div className="content-box-1">
                        <div className="text-center inactive-chat btn-margin">
                            <h4>
                                <p className='opps'>Oops!</p>
                                <p className='text-msg'> {chatboData?.message} Please Contact</p>
                                <p className='text-msg'>with Service Administrative</p>
                            </h4>
                        </div>
                    </div>
                )}
            </div>
            <div>{isShowLoading && <Loader />}</div>
        </>
    );
};

export default SimpleChatPage;