import { createContext, useContext } from "react";
import useLocalStorage from "../../CustomHooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

const BehaviorSubjectContext = createContext();

export const BehaviorSubjectProvider = ({ children }) => {
    const [isLoginUser, setisLoginUser, logout] = useLocalStorage('user', false);
    const navigate = useNavigate();

    return (
        <BehaviorSubjectContext.Provider value={{ isLoginUser, setisLoginUser, logout }}>
            {children}
        </BehaviorSubjectContext.Provider>
    );
};

export const useBehaviorSubject = () => useContext(BehaviorSubjectContext);