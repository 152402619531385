import { useNavigate } from 'react-router-dom';
import classes from './Pricing.module.css';

function Pricing({ planList }) {
    const navigate = useNavigate();

   function onPlanSelectHandler(){
    navigate('/signin');
   }

    return (
        <>
            <div className={`${classes['plan-section']} card`}>
                <div className={classes['plan-box']}>
                    <div className={classes['plan-section_1']}>
                        <div className={classes['plan-detail']}>
                            <div className={classes['plan-name']}>{planList.name}</div>
                            <div className={classes['plan-amount']}><span className={classes['plan-currency']}>$</span>{planList.price}</div>
                            <div className={classes['plan-duration']}>{planList.validity_in_days} Days</div>
                        </div>
                    </div>

                    <div className={classes['plan-section_2']}></div>

                    <div className={classes['plan-section_3']}>
                        <div className={classes['benefits-section']}>
                            <div><i className="fa-solid fa-check"></i></div>
                            <div className={classes.benefits}>{planList.chatbot_hits} message credits/month</div>
                        </div>
                        <div className={classes['benefits-section']}>
                            <div><i className="fa-solid fa-check"></i></div>
                            <div className={classes.benefits}>{planList.chatbot_count} chatbots</div>
                        </div>
                        <div className={classes['benefits-section']}>
                            <div><i className="fa-solid fa-check"></i></div>
                            <div className={classes.benefits}>{planList.chatbot_characters_limit} characters/chatbot</div>
                        </div>
                        <div className={classes['benefits-section']}>
                            <div><i className="fa-solid fa-check"></i></div>
                            <div className={classes.benefits}>GPT-4o (most advanced and efficient model)</div>
                        </div>
                    </div>

                    {/* <div className="plan-section_4">
                    Chatbots get deleted after 14 days of inactivity on the free plan.
                </div> */}
                </div>
                <div className={classes['plan-button']}>
                    <button className={classes['subscribe-btn']} onClick={onPlanSelectHandler}>Get Started</button>
                </div>
            </div>
        </>
    );
}

export default Pricing;