import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import hidePassword from "../../../../assets/hidePassword.png";
import showPassword from "../../../../assets/showPassword.png";
import ApiService from "../../../../services/Api";
import { useBehaviorSubject } from "../../../globalShared/Loader/BehaviorSubject/BehaviorSubject";
import { useState,useEffect} from "react";
import { toast } from "react-toastify";
import Loader from "../../../globalShared/Loader/Loader";
import basestyle from "./../baseStyle.module.css";

import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const Login = () => {
  const [isLoading, setisLoading] = useState(false);
  const { setisLoginUser } = useBehaviorSubject();
  const [hide, setHide] = useState(false);
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [isSubmit, setIsSubmit] = useState(false);

  //  state for userDetails
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
  });




  
  const [user, setUser] = useState(null);

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profilePic: "",
  });

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });


  const googleSign = (res) => {
    const { given_name, family_name, email, picture } = res.data;
    let request ={
      firstName: given_name,
      lastName: family_name,
      email: email,
      profilePic: picture,
    };

    setisLoading(true);

    ApiService.googleSignIn(request)
      .then((res) => {
        localStorage.setItem("token", res?.token);
        localStorage.setItem(
          "userDetail",
          JSON.stringify(res?.internalUserResponse)
        );
        setisLoading(false);
        setisLoginUser(true);
        navigate("/dashboard");
      })
      .catch((error) => {
        setisLoading(false);
        // toast.error(error?.message);
      });
  };



  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
            googleSign(res);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);



  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(name, value);
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }
  };

  const validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
      case "email":
        if (!value) errorMsg = "Please enter Email.";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = "Please enter a valid Email.";
        break;
      case "password":
        if (!value) errorMsg = "Please enter Password.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const validateForm = (form) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
      const msg = validateField(key, form[key]);
      if (msg) errorObj[key] = msg;
    });
    return errorObj;
  };

  const handleSubmit = (e) => {
    console.log("handleSubmit");
    e.preventDefault();
    const errors = validateForm(form);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {

      setisLoading(true);
      ApiService.signIn(form)
        .then((res) => {
          localStorage.setItem("token", res?.token);
          localStorage.setItem("userDetail", JSON.stringify(res?.internalUserResponse));
          setisLoading(false);
          setisLoginUser(true);
          navigate("/dashboard");
        })
        .catch((error) => {
          setisLoading(false);
          toast.error(error?.message);
        });
    }
    console.log("Data:", form);
  };


  const hideandShow = (value) => {
    setHide(value)
  };


  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <>
      <div className="box-aglin">
        <div className="login-box">
          <div className="heading-align">
            <h1>Welcome Back</h1>
          </div>
          <div>
            <form className="form-group">
              <div className="margin-div">
                <h4 className="lable-name">Email</h4>
                <div>
                  <input
                    onChange={changeHandler}
                    onBlur={changeHandler}
                    name="email"
                    value={form.email}
                    className="form-control"
                    type="text"
                    required
                  />
                  {formErrors.email && <span className={basestyle.error}>{formErrors.email}</span>}
                </div>
              </div>
              <div className="">
                <h4 className="lable-name">Password</h4>
                <div className="password-icon-postion input-with-icon">
                  <input
                    onChange={changeHandler}
                    onBlur={changeHandler}
                    name="password"
                    value={form.password}
                    className="form-control"
                    type={passwordType}
                    required
                  />
                  {form.password && (
                    <div className="icon-container">
                      <span className="eyeicon" onClick={viewPass}>
                        <i
                          className={`fa ${visible ? "fa-eye" : "fa-eye-slash"
                            }`}
                        />
                      </span>
                    </div>
                  )}
                  {/* <div className="icon">
                    {hide ? (
                      <img
                        onClick={() => {
                          hideandShow(false);
                        }}
                        src={hidePassword}
                      />
                    ) : (
                      <img
                        onClick={() => {
                          hideandShow(true);
                        }}
                        src={showPassword}
                      />
                    )}
                  </div> */}
                </div>
                {formErrors.password && (
                  <span className={basestyle.error}>{formErrors.password}</span>
                )}
              </div>
              <div className="forgot-section mt-2 margin-div">
                <Link to="/forgot-password">
                  <span className="forgot-text">Forgot Password?</span>
                </Link>
              </div>
              <div className="margin-div">
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="submit-btn"
                >
                  Sign in
                </button>
              </div>
              <div className="text-center margin-div">
                <h4>
                  <span className="dont-have">Don't an have account?</span>
                  <span className="sign-up-text">
                    <Link to="/"> Sign Up</Link>
                  </span>
                </h4>
              </div>
              <div className="other-option margin-div">
                <div className="text-div">
                  <span>OR CONTINUE WITH</span>
                </div>
                <div className="border-line"></div>
              </div>
              <div className="margin-div">
                <button type="button" className="submit-btn" onClick={() => login()}>
                  <i className="fab fa-google fab-color"></i>&nbsp; Sign
                  in with Google
                </button>
              </div>

              <div className="term-condition">
                <span>By continue, you are agree to our </span>
                <span>
                  <a href="">Term of Services</a>
                </span>
                <span> and </span>
                <span>
                  <a href="">Privacy Policy</a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>{isLoading && <Loader />}</div>
    </>
  );
};

export default Login;
