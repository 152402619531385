import React from "react";
import { Routes, Route } from "react-router-dom";
import RootLayout from "../../../../routes/RootLayout";
import AuthRoutes from "../../../../routes/AuthRoutes";
import UnAuthRoutes from "../../../../routes/UnAuthRoutes";
import ChatbotPage from "../../common/chatbot-injection/ChatbotPage";
import Contact from './../../common/Contact/Contact'
import ForgotPassword from "../../common/Forgot-Password/ForgotPassword";
import ChatBubblePage from "../../common/chatbot-bubble/ChatBubblepage";
import ResetPassword from './../../common/Reset-Password/ResetPassword';

function Main() {
  return (
    <div>
      <div>
        <Routes>
          <Route path="/" element={<RootLayout />}>
            {AuthRoutes}
            {UnAuthRoutes}
            <Route path="/contact" element={<Contact />} />
            <Route key="forgotPassword" path="/forgot-password" element={<ForgotPassword />} />
            <Route key="resetPassword" path="/reset-password/:token" element={<ResetPassword />} />
          </Route>
          <Route key="chatbubble" path="/chatbubble" element={<ChatBubblePage />} />
          <Route key="chatbot" path="/chatbot" element={<ChatbotPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default Main;