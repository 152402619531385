import React from "react";
import { useNavigate } from "react-router-dom";
import homePageLogo from '../../../../assets/left-chatbot.jpg'


function Home() {
  const navigate = useNavigate();

  function onPlanSelectHandler() {
    navigate('/signin');
  }

  return (
    <div>
      <main className="main-section fade-in">
        <div>
          <img src={homePageLogo} />
        </div>
        <div>
          <h1 className="main-title fade-in">Custom Chatbots for your website</h1>
          <div className="main-description fade-in">
            <p>
              Build a custom chatbot, embed it on your website and let it handle
              customer support, lead
            </p>
            <p>generation, engage with your users, and more.</p>
          </div>
          
          <div className="button-container fade-in">
            <button className="build-button" onClick={onPlanSelectHandler}>Build your Chatbot</button>
            {/* <button class="watch-button">Watch Video</button> */}
          </div>
          {/* <p class="no-card">No credit card required</p> */}
        </div>
      </main>
    </div>
  );
}

export default Home;
