import React from 'react';
import { useLocation } from 'react-router-dom';
import ChatBubble from './ChatBubble';

const ChatBubblePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chatbotKey = queryParams.get('chatbotKey');
  const planKey = queryParams.get('planKey');

  return (
    <div>
      <ChatBubble chatbotKey={chatbotKey} planKey={planKey} />
    </div>
  );
};

export default ChatBubblePage;