import ApiService from "../../../../../../services/Api";
import Loader from "../../../../../globalShared/Loader/Loader";
import "./Plans.css";
import { toast } from "react-toastify";
import { useState } from "react";

function Plans({ planList, currentUserPlan, updatePlanList}) {
    const [isLoading, setisLoading]= useState(false)
    const updatePlan=()=>{
    setisLoading(true)
    if(currentUserPlan){
        ApiService.updatePlans(planList?.id).then(res=>{
            toast.success(res?.message);
            setisLoading(false)
            updatePlanList()
        }).catch(error=>{
            setisLoading(false)
            toast.error(error?.message);
        })
    }
    if(!currentUserPlan){
        ApiService.addPlans(planList?.id).then(res=>{
            toast.success(res?.message);
            setisLoading(false)
            updatePlanList()
        }).catch(error=>{
            setisLoading(false)
            toast.error(error?.message);
        })
    }
  
}


    return (
        <>
        <div className="card plan-section">
            <div className="plan-box">
                <div className="plan-section_1">
                    <div className="plan-detail">
                        <div className="plan-name">{planList.name}</div>
                        <div className="plan-amount"><span className="plan-currency">$</span>{planList.price}</div>
                        <div className="plan-duration">{planList.validity_in_days} Days</div>
                    </div>
                    {currentUserPlan && planList.id === currentUserPlan.plan_id && (
                        <div>
                            <i className="fa-solid fa-circle-check fa-2xl" style={{ color: "#00ad79" }}></i>
                        </div>
                    )}
                </div>

                <div className="plan-section_2"></div>

                <div className="plan-section_3">
                    <div className="benefits-section">
                        <div><i className="fa-solid fa-check"></i></div>
                        <div className="benefits">{planList.chatbot_hits} message credits/month</div>
                    </div>
                    <div className="benefits-section">
                        <div><i className="fa-solid fa-check"></i></div>
                        <div className="benefits">{planList.chatbot_count} chatbots</div>
                    </div>
                    <div className="benefits-section">
                        <div><i className="fa-solid fa-check"></i></div>
                        <div className="benefits">{planList.chatbot_characters_limit} characters/chatbot</div>
                    </div>
                    {/* <div className="benefits-section">
                        <div><i class="fa-solid fa-check"></i></div>
                        <div className="benefits">1 team member</div>
                    </div>
                    <div className="benefits-section">
                        <div><i class="fa-solid fa-check"></i></div>
                        <div className="benefits">Limit to 10 links to train on</div>
                    </div>
                    <div className="benefits-section">
                        <div><i class="fa-solid fa-check"></i></div>
                        <div className="benefits">Embed on unlimited websites</div>
                    </div>
                    <div className="benefits-section">
                        <div><i class="fa-solid fa-check"></i></div>
                        <div className="benefits">Capture leads</div>
                    </div>
                    <div className="benefits-section">
                        <div><i class="fa-solid fa-check"></i></div>
                        <div className="benefits">View chat history</div>
                    </div> */}
                    <div className="benefits-section">
                        <div><i className="fa-solid fa-check"></i></div>
                        <div className="benefits">GPT-4o (most advanced and efficient model)</div>
                    </div>
                </div>

                {/* <div className="plan-section_4">
                    Chatbots get deleted after 14 days of inactivity on the free plan.
                </div> */}
            </div>

            { !currentUserPlan && planList && (
                <div className="plan-button">
                    <button onClick={updatePlan} className="subscribe-btn">Select Plan</button>
                </div>
            )}

            { currentUserPlan && planList.id !== currentUserPlan.plan_id && (
                <div className="plan-button">
                    <button onClick={updatePlan} className="subscribe-btn">Upgrade</button>
                </div>
            )}
            {currentUserPlan && planList.id === currentUserPlan.plan_id && currentUserPlan.is_active && (
                <div className="plan-button">
                    <button className="subscribe-btn btn" disabled>Active</button>
                </div>
            )}
        </div>
        {isLoading && ( 
        <Loader />
       )}
        </>
    );
}

export default Plans;
