import { useState, useEffect } from "react";
import ApiService from "../../../../../services/Api";
import Loader from "../../../../globalShared/Loader/Loader";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import classes from './Setting.module.css'
import { useNavigate } from "react-router-dom";
import copyIcon from '../../../../../assets/ic-copy.svg';
import visibilityIcon from '../../../../../assets/ic-eye.svg';
import hiddenIcon from '../../../../../assets/ic-eye-close.svg';

function Setting() {
    const userDetails = JSON.parse(localStorage.getItem('userDetail'))
    const [isLoading, setisLoading] = useState(false);
    const [response, setResponse] = useState('')
    const [inputType, setinputType] = useState('password');
    const [showModel, setShowModel] = useState(false)
    const navigate = useNavigate();
    const [isKeyVisible, setIsKeyVisible] = useState(false);

    const fetchPlans = async () => {
        setisLoading(true)
        await ApiService.planList().then(res => {
            setisLoading(false)
            setResponse(res?.userPlan)
        }).catch(error => {
            setisLoading(false)
        })
    };
    useEffect(() => {
        fetchPlans();
    }, []);

    const visibleKey = () => {
        setinputType('text')
        setIsKeyVisible(true);
    }
    const hideKey = () => {
        setinputType('password')
        setIsKeyVisible(false);
    }
    const handleCopy = () => {
        navigator.clipboard.writeText(response?.plan_key);
        toast.success('Text copied')
    }
    const showModelBox = () => {
        setShowModel(true)
    }

    const handleClose = () => {
        setShowModel(false)
    }
    const Deactivate = () => {
        setisLoading(true)
        ApiService.chatbotRemoveSubscription().then(res => {
            toast.success(res?.message)
            setisLoading(false)
            setShowModel(false)
            fetchPlans();
        }).catch(error => {
            setisLoading(false)
            toast.error(error?.message)
        })

    }
    const selectPlanRediret = () => {
        navigate('/')
    }
    return (
        <>
            {response && (
                <div className={`${classes['card']} card`}>
                    <div className={classes['card-box']}>
                        <div>
                            <div className={classes['subs-heading']}>User Name</div>
                            <div className={classes['subs-detail']}>{userDetails?.first_name} {userDetails?.last_name}</div>
                        </div>
                        <div>
                            <div className={classes['subs-heading']}>Plan Type</div>
                            <div className={classes['subs-detail']}>{response?.plan_id === 1 ? "Free" : response?.plan_id === 2 ? "Basic" : response?.plan_id === 3 ? "Silver" : response?.plan_id === 4 ? "Gold" : ''}</div>
                        </div>
                        <div>
                            <div className={`${classes['subs-heading']} pb-2`}>Subscription Key</div>
                            <div className={classes['subs-key-section']}>
                                <div className={classes['subscription-key_field']}>
                                    <input className="form-control" type={inputType} value={response?.plan_key} disabled={true} />
                                </div>
                                <div>
                                    {!isKeyVisible && (
                                        <img className={classes['cursor-icon']} title="view" src={visibilityIcon} onClick={visibleKey}/>
                                    )}
                                        {isKeyVisible && (
                                            <img className={classes['cursor-icon']} title="hide" src={hiddenIcon} onClick={hideKey}/>
                                        )}
                                </div>
                                <div>
                                    <img className={classes['cursor-icon']} title="copy" src={copyIcon} onClick={handleCopy}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type='button' onClick={showModelBox} className="btn btn-danger deactivate-btn">Deactivate Plan</button>
                        </div>
                    </div>
                </div>
            )}

            <div className='model-box'>
                <Modal show={showModel}>
                    <Modal.Header>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure, do you want deactivate this plan?</Modal.Body>
                    <Modal.Footer>
                        <button className='no-btn' onClick={handleClose}>
                            No
                        </button>
                        <button className='yes-btn' onClick={Deactivate}>
                            Yes
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                {isLoading && (
                    <Loader />
                )}
            </div>
        </>
    )
}

export default Setting;