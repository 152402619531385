import React from "react";
import classes from "./Contact.module.css";

export default function Contact() {
  return (
    <div className={classes.ContactContainer}>
      <div className={`${classes["contact-main-contain"]}`}>
        <div className={classes["contact-heading"]}>GET IN TOUCH</div>

        <div className={classes["contact-content"]}>

            
          <div className={`${classes["content-part"]} ${classes["sec-1"]}`}>
            <h3 className={classes["heading"]}>Genxcellence</h3>
            <div className={classes["text"]}>
              Genxcellence is a technology consulting organization specializing
              in outsourced product engineering services. Our solutions are
              focused on modern technologies.
            </div>

            <div className={classes["social-icons"]}>
              <a href="https://www.facebook.com/">
                <i className="fab fa-facebook" aria-hidden="true"></i>
              </a>
              <a href="https://www.linkedin.com/">
                <i className="fab fa-linkedin" aria-hidden="true"></i>
              </a>
            </div>
          </div>

          <div className={`${classes["content-part"]} ${classes["section-2"]}`}>
            <h3 className={classes["heading"]}>Contact Us</h3>


            <div className={classes["addr-contact"]}>
              <div><i class="fa fa-map-marker" aria-hidden="true"></i></div>
              <div className={classes["addr-content"]}>
                <span>India Office -</span> 724/B, Anand Vihar, Scheme no. 103,
                <br />
                Indore, India
                <br />
                <span>US Office -</span> 25221 Earhart, LAGUNA hills, ca 92653
              </div>
            </div>

            <div className={classes["addr-contact"]}>
              <div><i class="fa fa-phone" aria-hidden="true"></i></div>
              <div className={classes["addr-content"]}>
                <span>Phone -</span> (+91) - 99264-89187
              </div>
            </div>

            <div className={classes["addr-contact"]}>
              <div><i class="fa fa-envelope" aria-hidden="true"></i></div>
              <div className={`${classes["addr-content"]} ${classes["email-section"]}`}>
                <span>Email -</span> <a href="mailto:hr@genxcellenceinc.com">hr@genxcellenceinc.com</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
