import React, { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './SpeakText.css'
const SpeakText = (props) => {
    const [islistening, setislistening]=useState(false)
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    // if (!browserSupportsSpeechRecognition) {
    //     return <span>Browser doesn't support speech recognition.</span>;
    // }
    const start = () => {
        setislistening(true)
        SpeechRecognition.startListening({ continuous: true })
    }
    const stop = () => {
        setislistening(false)
        SpeechRecognition.stopListening();
        // props.stop(transcript)
    }

    return (
        <>
        {browserSupportsSpeechRecognition && (
        <div className='d-flex'>
            {!listening && (
            <div className='margin-mic-Icon' onClick={start} title="click to start speaking">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.6666 9.33341V14.6667C22.6666 18.3486 19.6818 21.3334 15.9999 21.3334C12.318 21.3334 9.33325 18.3486 9.33325 14.6667V9.33341C9.33325 5.65152 12.318 2.66675 15.9999 2.66675C19.6818 2.66675 22.6666 5.65152 22.6666 9.33341Z" stroke="rgba(3, 78, 193, 1)" stroke-width="2" />
                    <path d="M26.6666 14.6667C26.6666 20.5578 21.891 25.3334 15.9999 25.3334M15.9999 25.3334C10.1089 25.3334 5.33325 20.5578 5.33325 14.6667M15.9999 25.3334V29.3334M15.9999 29.3334H19.9999M15.9999 29.3334H11.9999" stroke="rgba(3, 78, 193, 1)" stroke-width="2" stroke-linecap="round" />
                </svg>
            </div>
            )}
    
            {listening && (
            <div className='mic_icon_stop' title="click to stop speaking"  onClick={stop}>
                  <svg width="23" height="23" viewBox="0 0 27 27" fill="white" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="24" height="24" rx="3" stroke-width="2"/>
                </svg>
            </div>
            )}
        </div>
        ) }
        </>
    );
};
export default SpeakText;
