import React from "react";
import { Route } from 'react-router-dom';
import Dashboard from '../components/pages/publicPages/home/Dashboard/Dashboard';
import AuthGuard from '../components/Guards/AuthGuard';
import Profile from './../components/pages/publicPages/home/Profile/Profile';
const AuthRoutes = [
  <Route key="Dashboard" path="/dashboard" element={<AuthGuard component={<Dashboard />} />} />,
  <Route key="Profile" path="/profile" element={<AuthGuard component={<Profile />} />} />
]

export default AuthRoutes;