import { useState, useRef } from "react";
import "./AddChatboat.css";
import ApiService from "../../../../../../services/Api";
import Loader from "../../../../../globalShared/Loader/Loader";
import { toast } from "react-toastify";
import basestyle from "./../../../../common/baseStyle.module.css";

const AddChatboat = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const [text, setText] = useState("");
  const fileInputRef = useRef(null);
  const [sourceType, setSourceTYpe] = useState("file");
  const [selectedFile, setSelectedFile] = useState(null);
  const [WebsiteUrl, setWebsiteUrl] = useState("");
  const [chatbotName, setChatbotName] = useState("");
  const [retrainValues, setRetrainValues] = useState(props?.data);
  const [isValid, setIsValid] = useState(false);

  // first add state
  // const [formErrors, setFormErrors] = useState({});
  const [chatbotNameError, setChatbotNameError] = useState(null);

  const handleTopicClick = (value) => {
    setSourceTYpe(value);
    setSelectedFile("");
    setChatbotName("");
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  // const handleWebsiteUrlChange =(e) =>{
  //   setWebsiteUrl(e.target.value);
  // }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    e.target.value = "";

    // uploadFile(file);
  };
  const viewLocalFile = () => {
    var url = window.URL.createObjectURL(selectedFile);
    window.open(url);
  };

  //sujeet changes here
  const uploadFile = () => {
    // e.preventDefault();
    let error='';
    if(!retrainValues.id){
    error = validateChatbotName(chatbotName);
    setChatbotNameError(error);
    }

    if (!error) {
      setisLoading(true);
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        ApiService.upload(formData)
          .then((res) => {
            if (retrainValues.id) {
              retainChatbot(res);
            } else {
              submit(res);
            }
          })
          .catch((error) => {
            setisLoading(false);
          });
      } else {
        if (retrainValues.id) {
          retainChatbot("");
        } else {
          submit("");
        }
      }
    }
  };

  const back = () => {
    props.backOnPage(true);
  };

  const submit = (fileUrl) => {
    const formData = {
      chatBotKey: "",
      chatBotModel: "",
      chatBotName: chatbotName,
      file: fileUrl,
      source: text,
      sourceType: sourceType,
    };

    setisLoading(true);
    ApiService.createChatbot(formData)
      .then((res) => {
        toast.success(res?.message);
        setisLoading(false);
        props.refreshListData(true);
      })
      .catch((error) => {
        toast.error(error?.message);
        setisLoading(false);
        if (
          error.message ===
          "You chat bot quota is full. Please upgrade your plan to create more chat bots."
        ) {
          props.refreshListData(true);
        }
      });
  };

  // sujeet code
  const retainChatbot = (fileUrl) => {
    const formData = {
      chatBotKey: retrainValues.chatbot_key,
      chatBotModel: retrainValues.gpt_model,
      chatBotName: retrainValues.chatbot_name,
      file: fileUrl,
      source: text,
      sourceType: sourceType,
    };

    console.log(formData);
    console.log(retrainValues.id);

    setisLoading(true);
    ApiService.retrainChatbot(formData, retrainValues.id)
      .then((res) => {
        toast.success(res?.message);
        setisLoading(false);
        props.refreshListData(true);
      })
      .catch((error) => {
        toast.error(error?.message);
        setisLoading(false);
        if (
          error.message ===
          "You chat bot quota is full. Please upgrade your plan to create more chat bots."
        ) {
          props.refreshListData(true);
        }
      });
  };

  const validateChatbotName = (name) => {
    if (!name) {
      return "Please enter Chatbot Name.";
    }
    return null;
  };

  const getChatbotName = (e) => {
    const { value } = e.target;
    setChatbotName(value);
    const error = validateChatbotName(value);
    setChatbotNameError(error);
  };

  const removeSelectedFile = (e) => {
    e.target.value = "";
    setSelectedFile(null);
  };

  return (
    <>
      <button className="back-button btn-width" onClick={back}>
        Back
      </button>
      <div className="NewChatbot-heading">
        <div className="section-1">Data Sources</div>
        <div className="section-2">
          Add your data sources to train your chatbot
        </div>
      </div>
      <div className="box-border">
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="sidebar">
              <button
                className={`sidebar-btn ${
                  sourceType === "file" ? "select-btn" : ""
                }`}
                onClick={() => handleTopicClick("file")}
              >
                <div className="text-with-icon">
                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                <span>Files</span>
                </div>
              </button>
              <button
                className={`sidebar-btn ${
                  sourceType === "text" ? "select-btn" : ""
                }`}
                onClick={() => handleTopicClick("text")}
              >
                 <div className="text-with-icon">
                <i class="fa fa-align-left" aria-hidden="true"></i>
                <span>Text</span>
                </div>
              </button>
              <button
                className={`sidebar-btn ${
                  sourceType === "website" ? "select-btn" : ""
                }`}
                onClick={() => handleTopicClick("website")}
              >
                 <div className="text-with-icon">
                <i class="fa fa-globe" aria-hidden="true"></i>
                <span>Website</span>
                </div>
              </button>
            </div>
          </div>
          <div className="col-md-9 col-sm-12 left-side-section">
            <div className="chatbot-name-field">
              <h4 className="headings-text">Chatbot Name *</h4>
              <input
                disabled={retrainValues.chatbot_name ? true : false}
                value={retrainValues.chatbot_name}
                className="form-control"
                placeholder="Please enter chatbot name"
                name="chatboatNAme"
                onChange={getChatbotName}
                onBlur={getChatbotName}
                required
              />
              {chatbotNameError && (
                <span style={{ margin: "0px" }} className={basestyle.error}>
                  {chatbotNameError}
                </span>
              )}
            </div>
            <div className="center">
              {/* textpage */}
              {sourceType === "file" && (
                <div>
                  <h4 className="headings-text">Files </h4>
                  <div className="file-upload-container">
                    <div className="file-upload-box" onClick={handleBoxClick}>
                      <p>Drag & drop files here, or click to select files</p>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept=".pdf, .doc, .docx, .txt"
                      />
                    </div>
                    <p className="file-types-info">
                      Supported File Types: .pdf, .doc, .docx, .txt
                    </p>
                    <p className="pdf-info">
                      If you are uploading a PDF, make sure you can
                      select/highlight the text.
                    </p>
                  </div>
                  {selectedFile && (
                    <div className="text-center">
                      <p>
                        <a className="hyper-link" onClick={viewLocalFile}>
                          {selectedFile?.name}
                        </a>
                        <span
                          title="remove file"
                          className="remove-icon"
                          onClick={removeSelectedFile}
                        >
                          <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              )}

              {/* textPage */}
              {sourceType === "text" && (
                <div className="text-input-secton">
                  <h4 className="headings-text">Text</h4>
                  <div className="text-input-container">
                    <textarea
                      // value={text}
                      onChange={handleTextChange}
                      placeholder="Enter text here..."
                      className="form-control"
                      rows="10"
                      cols="70"
                    ></textarea>
                  </div>
                  <div className="text-counter">
                    <p>{text.length} Characters</p>
                  </div>
                </div>
              )}

              {/* websitePage */}
              {sourceType === "website" && (
                <div>
                  <h4 className="headings-text">Website</h4>
                  <div className="website-tool-container">
                    <div className="crawl-section">
                      <h5>Crawl</h5>
                      <div className="input-section">
                        <input
                          type="text"
                          onChange={handleTextChange}
                          placeholder="https://www.example.com"
                        />
                        {/* <button className="btn-width">Fetch links</button> */}
                      </div>

                      {/* <p>
                          This will crawl all the links starting with the URL
                          (not including files on the website).
                        </p> */}
                    </div>
                    {/* <div className="or-section">
                        <hr style={{ width: "100%", margin: "0" }} />
                        <span className="or-content">Or</span>
                      </div> */}

                    {/* <div className="sitemap-section">
                        <h5>Submit Sitemap</h5>
                        <div className="input-section">
                          <input
                            type="text"
                            placeholder="https://www.example.com/sitemap.xml"
                          />
                          <button className="btn-width">Load sitemap</button>
                        </div>
                      </div>
                      <div className="included-links-section">
                        <div className="or-section">
                          <hr style={{ width: "100%", margin: "0" }} />
                          <span className="inclued-content">
                            {" "}
                            <h5>Included Links</h5>
                          </span>
                        </div>
                      </div> */}
                  </div>
                </div>
              )}
            </div>
            <div className="text-center">
              <div>
                {/* sujeet changes here*/}

                {retrainValues.id ? (
                  <button
                    // disabled={!retrainValues.chatbot_key}
                    className="btn-width"
                    onClick={uploadFile}
                  >
                    Retrain Chatbot
                  </button>
                ) : (
                  <button
                    disabled={!selectedFile && text.length === 0}
                    className="btn-width"
                    onClick={uploadFile}
                  >
                    Create Chatbot
                  </button>
                )}
              </div>
            </div>
          </div>
          {isLoading && (
            <div className="col-md-9 col-sm-12">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddChatboat;
