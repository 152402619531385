import React from "react";
import classes from "./Profile.module.css";

export default function Profile() {
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));

  // className={classes[""]}
  // {`${classes["row"]} row`}

  return (
    <div>
      <div className={`${classes["profile-main"]}`}>

          <div className={`${classes["profile-heading"]}`}>Profile</div>

          <div className={`${classes["profile-content"]}`}>

            <div className={`${classes["section"]}`}>
              <span className={classes["text-name"]}>First Name</span>
              <span className={classes["text-colon"]}>:</span>
              <span className={classes["text-value"]} id="firstName">
                {userDetails.first_name}
              </span>
            </div>
            <div className={`${classes["section"]}`}>
              <span className={classes["text-name"]}>Last Name</span>
              <span className={classes["text-colon"]}>:</span>
              <span className={classes["text-value"]} id="lastName">
                {userDetails.last_name}
              </span>
            </div>
            <div className={`${classes["section"]}`}>
              <span className={classes["text-name"]}>Email Address</span>
              <span className={classes["text-colon"]}>:</span>
              <span className={classes["text-value"]} id="email">
                {userDetails.email}
              </span>
            </div>
          </div>
        </div>
      </div>
  );
}
