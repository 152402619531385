import axios from "axios";
import { useNavigate } from "react-router-dom";
const apiUrl=process.env.REACT_APP_API_KEY;
const axiosInstance = axios.create({
    baseURL: apiUrl,
  });
  
  axiosInstance.interceptors.request.use(
    config => {
      const token=localStorage.getItem('token')
    if(token){
      config.headers.Authorization = `Bearer ${token}`;
    }
    if(apiUrl+'chatbot/conversation'===(config.baseURL + config.url)){
      config.headers.Authorization = `yVI5G25ZkhxFjqcwsDiOeg==`;
    }
        
      return config;
    },
    error => {
      throw error;
    }
  );
  
  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if(error?.response.status===401){
        localStorage.removeItem('token');
        localStorage.removeItem("user");
        NavigateData()
        return
       
      }

      throw error;
      //return Promise.reject(error);
    }
  );
  function NavigateData(){
    const navigate = useNavigate();
    navigate('/sign-in');
    return 
  }
  export default axiosInstance;

