import classes from "./SignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "../../../../services/Api";
import { toast } from "react-toastify";
import Loader from "../../../globalShared/Loader/Loader";
import { useState, useEffect } from "react";
import basestyle from "./../baseStyle.module.css";
import { useBehaviorSubject } from "../../../globalShared/Loader/BehaviorSubject/BehaviorSubject";

import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function SignUp() {
  // *********************************
  const { setisLoginUser } = useBehaviorSubject();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isSubmit, setIsSubmit] = useState(false);

  //  state for userDetails
  const [form, setForm] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    fname: null,
    lname: null,
    email: null,
    password: null,
    cpassword: null,
  });

  //

  const [user, setUser] = useState(null);

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profilePic: "",
  });

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });


  // const login = useGoogleLogin({
  //   onSuccess: (response) => {
  //     setState(response);
  //   },

  const googleSign = (res) => {
    const { given_name, family_name, email, picture } = res.data;
    let request ={
      firstName: given_name,
      lastName: family_name,
      email: email,
      profilePic: picture,
    };

    setisLoading(true);

    ApiService.googleSignIn(request)
      .then((res) => {
        localStorage.setItem("token", res?.token);
        localStorage.setItem(
          "userDetail",
          JSON.stringify(res?.internalUserResponse)
        );
        setisLoading(false);
        setisLoginUser(true);
        navigate("/dashboard");
      })
      .catch((error) => {
        setisLoading(false);
        // toast.error(error?.message);
      });
  };



  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
            googleSign(res);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);





  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(
        name,
        value,
        form[name === "password" ? "cpassword" : "password"]
      );
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }
  };

  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "fname":
        if (!value) errorMsg = "Please enter First Name.";
        break;
      case "lname":
        if (!value) errorMsg = "Please enter Last Name.";
        break;
      case "email":
        if (!value) errorMsg = "Please enter Email.";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = "Please enter a valid Email.";
        break;
      case "password":
        if (!value) errorMsg = "Please enter Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password do not match.";
        break;
      case "cpassword":
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password do not match.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const validateForm = (form) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
      const refValue =
        key === "password"
          ? form.cpassword
          : key === "cpassword"
          ? form.password
          : null;
      const msg = validateField(key, form[key], refValue);
      if (msg) errorObj[key] = msg;
    });
    return errorObj;
  };

  const handleSubmit = (e) => {
    console.log("handleupsubmit");
    e.preventDefault();
    const errors = validateForm(form);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsSubmit(true);
      const formData = {
        emailId: form.email,
        firstName: form.fname,
        lastName: form.lname,
        mobCountryCode: null,
        mobileNo: null,
        password: form.password,
        profilePic: "",
      };

      setisLoading(true);
      ApiService.userRegistration(formData)
        .then((res) => {
          setisLoading(false);
          toast.success(res?.message);
          navigate("/sign-in");
        })
        .catch((error) => {
          setisLoading(false);
          toast.error(error?.message);
        });
    }
    console.log("Data:", form);
    // setIsSubmit(true);
  };

  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const viewConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
    setConfirmPasswordType(
      confirmPasswordType === "password" ? "text" : "password"
    );
  };
  return (
    <div className={classes["main-container"]}>
      <div className={classes["sign_up-box"]}>
        <div className={classes.header}>Get started for free</div>

        <form onSubmit={handleSubmit}>
          <div className={classes["form-section"]}>
            <div className={classes["form-field"]}>
              <div>
                <label
                  htmlFor="exampleInputFirstName"
                  className={`${classes.label} form-label`}
                >
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="fname"
                  value={form.fname}
                  onChange={changeHandler}
                  onBlur={changeHandler}
                  className="form-control"
                  id="exampleInputFName"
                />
                {formErrors.fname && (
                  <span className={basestyle.error}>{formErrors.fname}</span>
                )}
              </div>
              <div>
                <label
                  htmlFor="exampleInputLastName"
                  className={`${classes.label} form-label`}
                >
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lname"
                  value={form.lname}
                  onChange={changeHandler}
                  onBlur={changeHandler}
                  className="form-control"
                  id="exampleInputLName"
                />
                {formErrors.lname && (
                  <span className={basestyle.error}>{formErrors.lname}</span>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="exampleInputEmail1"
                className={`${classes.label} form-label`}
              >
                Email
              </label>
              <input
                onChange={changeHandler}
                type="text"
                placeholder="name@example.com"
                name="email"
                value={form.email}
                onBlur={changeHandler}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              {formErrors.email && (
                <span className={basestyle.error}>{formErrors.email}</span>
              )}
            </div>
            <div>
              <label
                htmlFor="exampleInputPassword1"
                className={`${classes.label} form-label`}
              >
                Password
              </label>

              <div className={classes["input-with-icon"]}>
                <input
                  onChange={changeHandler}
                  onBlur={changeHandler}
                  type={passwordType}
                  placeholder="Password"
                  name="password"
                  value={form.password}
                  className="form-control "
                  id="exampleInputPassword1"
                />
                {form.password && (
                  <div className={classes["icon-container"]}>
                    <span className={classes["eyeicon"]} onClick={viewPass}>
                      <i
                        className={`fa ${visible ? "fa-eye" : "fa-eye-slash"}`}
                      />
                    </span>
                  </div>
                )}
              </div>

              {formErrors.password && (
                <span className={basestyle.error}>{formErrors.password}</span>
              )}
            </div>
            <div>
              <label
                htmlFor="exampleInputConfirmPassword"
                className={`${classes.label} form-label`}
              >
                Confirm Password
              </label>
              <div className={classes["input-with-icon"]}>
                <input
                  onChange={changeHandler}
                  onBlur={changeHandler}
                  type={confirmPasswordType}
                  placeholder="Password"
                  name="cpassword"
                  value={form.cpassword}
                  className="form-control"
                  id="exampleInputConfirmPassword"
                />
                {form.cpassword && (
                  <div className={classes["icon-container"]}>
                    <span
                      className={classes["eyeicon"]}
                      onClick={viewConfirmPass}
                    >
                      <i
                        className={`fa ${
                          visibleConfirm ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </span>
                  </div>
                )}
              </div>

              {formErrors.cpassword && (
                <span className={basestyle.error}>{formErrors.cpassword}</span>
              )}
            </div>
            <div className="mt-2">
              <button className={classes["submit-btn"]}>Sign up</button>
            </div>
          </div>
        </form>

        <div className={classes["signin-section"]}>
          Already have an account? &nbsp;
          <Link to="/sign-in">
            <span className={classes["signin-text"]}>Sign in</span>
          </Link>
        </div>
        <div className={classes["google-signin-section"]}>
          <div className={classes["google-signin-section-text"]}>
            <hr />
            <div>OR CONTINUE WITH</div>
            <hr />
          </div>
          <div className={classes["google-signin-section-btn"]}>
            {/* <button type="submit" className={classes["google-btn"]}>
              <i className={`${classes.icon_G} fab fa-google`}></i> &nbsp;
              Google
            </button> */}
            <button
              type="submit"
              className={classes["google-btn"]}
              onClick={() => login()}
            >
              <i className={`${classes.icon_G} fab fa-google`}></i> &nbsp; Sign
              in with Google
            </button>
          </div>
        </div>
        <div className={classes["terms-and-policy"]}>
          By continuing, you agree to our <Link href="">Terms of Service</Link>{" "}
          and <Link href="">Privacy Policy</Link>
        </div>
      </div>
      <div>{isLoading && <Loader />}</div>
    </div>
  );
}

export default SignUp;
