import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useBehaviorSubject } from "../../globalShared/Loader/BehaviorSubject/BehaviorSubject";
import profile from '../../../assets/profile-blue.svg';
import gcon_logo from "../../../assets/main_logo.svg";
const Header = () => {
  const { isLoginUser, logout } = useBehaviorSubject();
  const navigate = useNavigate();
  const [profileDropDown, setprofileDropDown] = useState();
  const dropdownRef = useRef(null);
  const userDetails=JSON.parse(localStorage.getItem('userDetail'))

  const handleChatbaseClick = () => {
    navigate('/');
  };

  const viewProfileClick=()=>{
    setprofileDropDown(false);
    navigate('/profile');
  }

  function onProfileHandler() {
    setprofileDropDown(!profileDropDown);
  }
  
  function onLogOutHandler() {
    setprofileDropDown(false);
    logout();
  }

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutsideHandler);
    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler);
    };
  }, []);

  function onClickOutsideHandler(event){
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setprofileDropDown(false);
    }
  }

  return (
    <div>
      <div className="header">
        <div className="header-left">
          <img className="header-logo" onClick={handleChatbaseClick} src={gcon_logo} alt=""/>
          {!isLoginUser && (
            <nav className="nav">
            {/* <Link to="/affiliates" className="nav-a">
              Affiliates
            </Link> */}
            <Link to="/pricing" className="nav-a">
              Pricing
            </Link>
             <Link to="/our-clients" className="nav-a">
             Our Clients
            </Link>
            {/* <div className="dropdown">
              <button className="dropdown-btn">Our Clients</button>
            </div> */}
          </nav>
          )}
        </div>
        {!isLoginUser && (
          <div className="header-right">
            <Link to="/sign-in" className="signin">
              Sign in
            </Link>
            <Link to="/signin" className="signin">
              Sign up
            </Link>
            <Link to="/signin" className="try-button">
              Try for Free
            </Link>
          </div>
        )}
        {isLoginUser && (
          <div className="img-circle" ref={dropdownRef}>
            <img src={profile} alt="Profile" className="image-logo" onClick={onProfileHandler} />
            {profileDropDown && (
              <div className="dropDownProfile">
                <ul className="drop-down-items">
                  <li className="open-dropdown">
                    <img src={profile} alt="Profile" className="dropdown-image-logo" />
                    <p className="dropdown-username">{userDetails?.first_name} {userDetails?.last_name}</p>
                    </li>
                  <li className="dropdown-item-text" onClick={viewProfileClick}>View Profile</li>
                  <li className="dropdown-item-text" onClick={onLogOutHandler}>Logout</li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
