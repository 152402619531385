
import axiosInstance from './Interceptor'
const ApiService = {
  signIn: async (data) => {
    try {
      const response = await axiosInstance.post('/auth/signin', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  googleSignIn: async (data) => {
    try {
      const response = await axiosInstance.post('/auth/oauth2/callback', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  userRegistration: async (data) => {
    try {
      const response = await axiosInstance.post('/user/register-user', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  chatbotList: async () => {
    try {
      const response = await axiosInstance.get('/chatbot/list');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getSubscriptionKey: async () => {
    try {
      const response = await axiosInstance.get('/chatbot/subscriptionKey');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  upload: async (file) => {
    try {
      const response = await axiosInstance.post('/chatbot/upload-file', file);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  createChatbot: async (data) => {
    try {
      const response = await axiosInstance.post('/chatbot/add', data);
      return response.data;
    } catch (error) {
      throw  error.response.data
    }
  },

  // sujeet code
  retrainChatbot: async (data, id) => {
    try {
      const response = await axiosInstance.post(`/chatbot/retrain?chatbotId=${id}`, data);
      return response.data;
    } catch (error) {
      throw  error.response.data;
    }
  },

  deactivateChatbot: async (id) => {
    try {
      const response = await axiosInstance.post('/chatbot/deactivate?chatbotId='+id);
      return response.data;
    } catch (error) {
      throw  error.response.data
    }
  },
  planList: async () => {
    try {
      const response = await axiosInstance.get('/plans/list');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  updatePlans: async (id) => {
    try {
      const response = await axiosInstance.post('/plans/update?planId='+id);
      return response.data;
    } catch (error) {
      throw  error.response.data
    }
  },
  addPlans: async (id) => {
    try {
      const response = await axiosInstance.post('/plans/add?planId='+id);
      return response.data;
    } catch (error) {
      throw  error.response.data
    }
  },

  publicPlanList: async () => {
    try {
      const response = await axiosInstance.get('plans/public/list');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  planDeactivate: async (id) => {
    try {
      const response = await axiosInstance.post('plans/deactivate?planId='+id);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  chatbotRemoveSubscription : async () => {
    try {
      const response = await axiosInstance.post('chatbot/remove-subscription');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  chatbotConversation : async (data) => {
    try {
      const response = await axiosInstance.post('chatbot/conversation', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  userForgotPassword : async (data) => {
    try {
      const response = await axiosInstance.put('user/recover-password', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  getChatbotStatus : async (key) => {
    try {
      const response = await axiosInstance.post('chatbot/active-details?chatbotKey='+key);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  userResetPassword : async (data) => {
    try {
      const response = await axiosInstance.post('user/reset-password', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  
  resetToken: async (token) => {
    try {
      const response = await axiosInstance.get('user/email/' + token);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

};

export default ApiService;