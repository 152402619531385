import React from "react";
import "./styles/styles.css";
import  Main from './components/pages/publicPages/main/Main'

function App() {
  return (
    <div>
  <Main />
  </div>
  );
}

export default App;
