import { useState } from 'react';
import './Dashboard.css'
import Chatbot from './../Chatboat_Details/chatbot/Chatbot';
import PlansList from '../PlansList/PlansList';
import Setting from '../Setting/Setting';

const Dashboard = () => {
    const [tab, setTab] = useState(1);
    const [flag, setFlag] = useState(false);
    const [havePlan, setHavePlan] = useState(false);
    const tabChange = (value, flag) => {
        setTab(value)
        setFlag(flag)
    }
    const viewChatBot=()=>{
        setHavePlan(true)
    }

    const Plan = () => {
        if (tab === 1) {
            return (
                <>
                    <div>
                       <PlansList  getTabChanges={tabChange} selectTab={flag} viewChatboat={viewChatBot}/>
                    </div>
                </>
            )
        }
    }
    const ChatbotSection = () => {
        if (tab === 2) {
            return (
                <>
                    <div>
                         <Chatbot/>
                    </div>
                </>
            )
        }
    }
    const Settings = () => {
        if (tab === 3) {
            return (
                <>
                    <div>
                      <Setting />
                    </div>
                </>
            )
        }

    }

    return (
        <>
            <div>
                <div className="tab-section">
                    <div onClick={() => { tabChange(1, true) }} className={tab === 1 ? 'seleted-border' : 'normal-border'}>Plans</div>
                    {havePlan && (<div onClick={() => { tabChange(2, false) }} className={tab === 2 ? 'seleted-border' : 'normal-border'}>Chatbots</div>)}
                    {havePlan && (<div onClick={() => { tabChange(3, false) }} className={tab === 3 ? 'seleted-border' : 'normal-border'}>Settings</div>)}
                </div>
            </div>
            <div className="container">
                <Settings />
                <Plan />
                <ChatbotSection />
            </div>
        </>
    )
}
export default Dashboard;