import "./Chatbot.css";
import iconchatbot from "./../../../../../../assets/icon-chatbot.svg";
import noChatBot from "./../../../../../../assets/ic-no-chatbot.svg";
import ChatboatDtails from "../Chatboat_Details";
import { useEffect, useState } from "react";
import ApiService from "../../../../../../services/Api";
import AddChatboat from "../AddChatboat/AddChatboat";
import Loader from "../../../../../globalShared/Loader/Loader";
export default function Chatbot() {
  const [showCard, setshowCard] = useState(false);
  const [showNewChatbotForm, setShowNewChatbotForm] = useState(false);
  const [data, setData] = useState("");
  const [chatbostList, setChatboatList]=useState([])
  const [isLoading, setisLoading] = useState(false);
  const [planSubscriptionKey, setKey] = useState("");
const [retrainData, setRetrainData]= useState('');

  const ChatboatInfo = (value) => {
    setshowCard(true);
    setData(value);
  };

  const getData =()=>{
    setisLoading(true)
    ApiService.chatbotList().then(res => {
      if(!res){
        res = [];
      }
      setChatboatList(res);
      setisLoading(false)
    },error=>{
      setChatboatList([]);
      setisLoading(false)
    });
  }

  const getSubscriptionKey =()=>{
    setisLoading(true)
    ApiService.getSubscriptionKey().then(res => {
      setKey(res?.details['subscription-key']);
      setisLoading(false)
    },error=>{
      setKey("");
      setisLoading(false)
    });
  }

  useEffect(() => {
    getData()
    getSubscriptionKey()
  }, []);
  
  const handleNewChatbotClick = () => {
    setShowNewChatbotForm(true);
  };

  const refreshList = (value) =>{
    console.log(value)
    if(value){
      setshowCard(false);
      setShowNewChatbotForm(false);
      getData()
    }
  }
  const back= (value)=>{
      setshowCard(false);
      setShowNewChatbotForm(false);
  }

  const getRetrain=(value)=>{
    console.log("sadggasd", value)
    setShowNewChatbotForm(true);
    setshowCard(false);
    setRetrainData(value);
  }

  function NewChatBot(){
    return (
      <>
      <div>
        <div>
          Chatbot
        </div>
        <div>
          <button onClick={handleNewChatbotClick}>New Chatboat</button>
        </div>
      </div>
      </>
    )
  }

  function ChatBoatCard() {
    return (
      <div className='chatbot-main-container'>
        <div className={'header-section'}>
          <div>
            <span className="heading">Chatbots</span>
          </div>
          <div className="new-chat-bot">
            <button className="chatbot-btn"  onClick={handleNewChatbotClick}>New Chatbot</button>
          </div>
        </div>

        {chatbostList?.length>0 && ( <div className="body-section box-border">
          <div className=" row ">
            {chatbostList?.map((item, index) => (
              <div
                key={index}
                className=" col-sm-12 col-md-4 col-lg-4"
                onClick={() => {
                  ChatboatInfo(item);
                }}
              >
                <div className="card curser-pointer"  onClick={() => {ChatboatInfo(item)}}>
                <div className="file-icon">
                  <img src={iconchatbot} alt="File Icon" />
                </div>
                <div className="file-details">
                  <span className="file-name">{item.chatbot_name}</span>
                </div>
              </div>
              </div>
            ))}
          </div>
        </div>)}
        {chatbostList?.length==0 && (
          <div className="box-border-no-data">
            <img src={noChatBot} />
            <p><span className="no-chatbot">No Chatbot </span> are available at the moment.</p>
            </div>
        )}
      </div>
    );
  }
if(isLoading){
  return (
    <div>
      <Loader />
    </div>
  )
}
  if(chatbostList?.length>0){
    <NewChatBot />
  }
  if (!showCard && !showNewChatbotForm) {
    return <ChatBoatCard />;
  }

  if (showCard) {
    return (
      <ChatboatDtails data={data} planSubscriptionKey={planSubscriptionKey} refreshListData={refreshList}  backOnPage={back} retrain={getRetrain}/>
    )
    
  }

  if (showNewChatbotForm) {
    return <AddChatboat refreshListData={refreshList}   backOnPage={back}  data={retrainData}/>;
  }

}



