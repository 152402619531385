import { useEffect, useState } from "react";
import ApiService from "../../../../../services/Api";
import Loader from '../../../../globalShared/Loader/Loader';
import classes from './PricingList.module.css';
import Pricing from "./Pricing/Pricing";


function PricingList() {
  const [plans, setPlans] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      setisLoading(true)
      await ApiService.publicPlanList().then(res => {
        setisLoading(false)
        setPlans(res);
      }).catch(error => {
        setisLoading(false)

      })
    };
    fetchPlans();
  }, []);
  console.log('plans: ', plans);

  return (
    // <div className={`${classes['main-block-container']}, container`}>
    <div className={classes['main-block-container']}>
      <div className={classes['heading-block']}>
        <h1 className={classes['block-1']}>Genxcellence Chatbot Plans.<span className={classes['block-2']}> Scale Anytime</span></h1>
        {/* <h1 className="block-2">Scalable Plans</h1> */}
      </div>
      <div className={classes['plans-and-subscriptions']}>
        {plans.map((item) => <Pricing key={item.id} planList={item} />)}
      </div>
      <div>
        {isLoading && (<Loader />)}
      </div>
    </div>
  )
}

export default PricingList