import Footer from "../components/pages/common/footer/Footer";
import Header from "../components/pages/common/Header";
import { Outlet } from "react-router-dom";
import { BehaviorSubjectProvider } from "../components/globalShared/Loader/BehaviorSubject/BehaviorSubject";

function RootLayout() {

    return (
        <BehaviorSubjectProvider>
            <Header />
            <div className="main-contain">
            <Outlet />
            </div>
            <Footer />
        </BehaviorSubjectProvider>
    )
}

export default RootLayout;