import ApiService from '../../../../../services/Api';
import Loader from '../../../../globalShared/Loader/Loader';
import './Chatboat_Details.css'
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify"; 
import { Modal } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/prism';
import copyIcon from '../../../../../assets/ic-copy.svg';
import { useNavigate } from 'react-router-dom';
import SimpleChatPage from '../../../common/chatbot-injection/SimpleChatPage';

const ChatboatDtails = (props) => {
    const [isLoading, setisLoading] = useState(false)
    const [showModel, setShowModel] = useState(false)
    const [tab, setTab] = useState(1);
    const [lastTrainedDate, setLastTrainedDate] = useState();
    const apiUrl = process.env.REACT_APP_API_KEY + '/chatbot/conversation';
    const webUrl = process.env.REACT_APP_WEB_KEY;
    const navigate= useNavigate();
    const [chabotOpen, setChabotOpen]=useState(true)
    const requestBody = {
        chatBotKey: props?.data.chatbot_key,
        query: "<YOUR_CHAT_QUERY_HERE>",
    };
    const responseBody = {
        message: "<Response from AI chatbot>",
        generatedId: 0,
        validity: true,
        details: {
            response: "<Response from AI chatbot>",
            query: "<User input query>"
        }
    }
    const headers = {
        "Content-Type": "application/json",
        "authorization ": props?.planSubscriptionKey
    };

    // const postmanCurl = `
    // curl --location --request POST `+apiUrl+ `\\
    // --header 'Content-Type: application/json' \\
    // --header 'authorization:'`+ props?.planSubscriptionKey+`' \\
    // --data-raw '{
    // "Content-Type": "application/json",
    // "authorization ": `+props?.planSubscriptionKey+`
    // }'
    // `;

    const postmanCurl = `curl --location --request POST ` + apiUrl + `\
    --header 'authorization:'`+ props?.planSubscriptionKey + `' \
    --header 'Content-Type: application/json' \
    --data '{
    "chatBotKey":`+ props?.data.chatbot_key + `,
    "query": "<Your query for chatbot>"
    }'`;

    const iFrame =
        `<iframe
    src="`+ webUrl + `/chatbot?chatbotKey=` + props?.data.chatbot_key + `&planKey=` + props?.planSubscriptionKey + `"
    width="100%"
    style="height: 100%; min-height: 700px;"
    frameborder="0" allow="camera; microphone"
    ></iframe>`;

    const script = `<script>
    window.embeddedChatbotConfig = {
      chatbotId: "`+ props?.data.chatbot_key + `",
      planKey:"`+ props?.planSubscriptionKey + `",
      domain: "`+ process.env.REACT_APP_WEB_KEY + `",
      width: "550px",
      height: "650px"
    }
    </script>
    <script
        src="`+ process.env.REACT_APP_WEB_KEY + `/embedpure.js" defer>
    </script>`;

    useEffect(() => {
        formatDateTime();
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(props?.data.chatbot_key);
        toast.success('Text copied')
    }
    const Deactivate = () => {
        setisLoading(true)
        ApiService.deactivateChatbot(props.data.id).then(res => {
            toast.success(res?.message)
            setisLoading(false)
            setShowModel(false)
            props.refreshListData(true)

        }).catch(error => {
            setisLoading(false)
            toast.error(error?.message)
        })

    }
    const Retrain = () => {
        props.retrain(props.data);
    }

    const back = () => {
        props.backOnPage(true)
    }

    const showModelBox = () => {
        setShowModel(true)
    }

    const handleClose = () => {
        setShowModel(false)
    }

    const formatDateTime = () => {
        const dateTime = new Date(props.data.last_trained_date);
        let formattedDateTime;
        const [month, day, year] = [
            dateTime.getMonth(),
            dateTime.getDate(),
            dateTime.getFullYear(),
        ];
        const time = dateTime.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })

        formattedDateTime = year + '-' + month + '-' + day + ' ' + time;
        setLastTrainedDate(formattedDateTime);
    }

    const tabChange = (value) => {
        setTab(value)
    }

    const backChatDetails =()=>{
        setChabotOpen(true)
    }
    const Conversation =()=>{
        setChabotOpen(false)
        // navigate(`/chatbot?chatbotKey=`+props?.data.chatbot_key+`&planKey`+props?.planSubscriptionKey);
    }
    const copyIframeHandler = () => {
        navigator.clipboard.writeText(iFrame);
        toast.success('Text copied')
    }

    const copyScriptHandler = () => {
        navigator.clipboard.writeText(script);
        toast.success('Text copied')
    }

    return (
        <>
        { chabotOpen && (
        <div>
            <button className="back-button btn-width" onClick={back}>Back</button>
            <div className='box-border'>
                <div className="row chatboat-info">
                    <div className="col-md-5 col-sm-12">
                        <div>
                            <h2>{props?.data.chatbot_name}</h2>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12">
                        <div className="chatbot-details-tabs">
                            <div onClick={() => { tabChange(1) }} className={tab === 1 ? 'seleted-tab' : 'default-tab'}>Web Integration</div>
                            <div onClick={() => { tabChange(2) }} className={tab === 2 ? 'seleted-tab' : 'default-tab'}>API Integration</div>
                        </div>
                        {/* <div>
                            <h3><u>API Integration details</u></h3>
                        </div> */}
                    </div>
                </div>
                <div className="row chatboat-info">
                    <div className="col-md-5 col-sm-12 chatboat-info-left">
                        <div className='chat-bot-id'>
                            <div className="chatbot-heading">
                                <h4>Chatbot id</h4>
                                <div><i className="light fa fa-lightbulb-o"></i></div>
                            </div>

                            <div className='chatboat-id-name'>
                                <div>
                                    <span className="heading-data">{props?.data.chatbot_key}</span>
                                    <span className='copy-icon'><i title='Copy Text' onClick={handleCopy} className="fa fa-clone"></i></span>
                                </div>

                            </div>
                        </div>
                        <div className='status_model'>
                            <div>
                                <h4>Status</h4>
                                <div className='status_model-green'>
                                    <div className='green-icon'></div>
                                    <div className="heading-data">Trained</div>
                                </div>
                            </div>
                            <div>
                                <h4>Model</h4>
                                <div className="heading-data">{props?.data.gpt_model}</div>
                            </div>
                        </div>
                        <div className='status_model'>
                            <div>
                                <h4>Total Chatbot Hits</h4>
                                <div className='status_model-green'>
                                    <div className="heading-data">{props?.data.total_hits}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4>Utilised Chatbot Hits</h4>
                            <div className="heading-data">{props?.data.used_hits}</div>
                        </div>
                        <div>
                            <h4>Visiblity</h4>
                            <div className="heading-data">Public</div>
                        </div>

                        <div>
                            <h4>Last trained at</h4>
                            <p className="heading-data">{lastTrainedDate}</p>
                        </div>

                        <div className="buttons-container">
                            <div>
                                <button className='chatbot-btn' onClick={Retrain}><i className=" icons fa fa-refresh"></i>Retrain</button>
                            </div>
                            <div>
                                <button className='btn btn-danger' onClick={showModelBox}><i className="icons fa fa-trash-o"></i>Deactivate</button>
                            </div>
                            <div>
                                <button className='chatbot-btn' onClick={Conversation}><i className=" icons far fa-comment-alt"></i>Conversation</button>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-7 col-sm-12">
                        <div className='chat-bot-border'>
                            {tab === 1 && (
                                <div className="web-integration">
                                    <div className="web-integration-header">
                                        <h3 className="heading">Embed</h3>
                                    </div>

                                    <div className="web-integration-padding">
                                        <div className="web-integration-flex">
                                            <div className="web-integration-block">
                                                <div className="web-integration-over-flow">
                                                    <div className="web-integration-section">
                                                        <div className="web-integration-box">
                                                            <div className="web-integration-content">
                                                                <div>
                                                                    <div className="web-integration-sub-header">
                                                                        <p className="sub-heading">To add a chat bubble to the bottom right of your website add this script tag to your html</p>
                                                                    </div>
                                                                    <div className="web-integration-data">
                                                                        <SyntaxHighlighter language="json" className="web-integration-value">
                                                                            {script}
                                                                        </SyntaxHighlighter>
                                                                    </div>
                                                                    <div className="web-integration-button">
                                                                        <button className="btn button" onClick={copyScriptHandler}>Copy Script <i className="fa fa-clone"></i></button>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="web-integration-sub-header">
                                                                        <p className="sub-heading">To add the chatbot any where on your website, add this iframe to your html code</p>
                                                                    </div>
                                                                    <div className="web-integration-data">
                                                                        <SyntaxHighlighter language="json" className="web-integration-value">
                                                                            {iFrame}
                                                                        </SyntaxHighlighter>
                                                                    </div>
                                                                    <div className="web-integration-button">
                                                                        <button className="btn button" onClick={copyIframeHandler}>Copy Iframe <i className="fa fa-clone"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {tab === 2 && (
                                <div>
                                    <section>
                                        <h2>API URL</h2>
                                        <SyntaxHighlighter language="json" className="web-integration-value">
                                            {apiUrl}
                                        </SyntaxHighlighter>
                                    </section>

                                    <section>
                                        <h2>Headers</h2>
                                        <SyntaxHighlighter language="json" className="web-integration-value">
                                            {JSON.stringify(headers, null, 2)}
                                        </SyntaxHighlighter>
                                    </section>

                                    <section>
                                        <h2>Request Body</h2>
                                        <SyntaxHighlighter language="json" className="web-integration-value">
                                            {JSON.stringify(requestBody, null, 2)}
                                        </SyntaxHighlighter>
                                    </section>

                                    <section>
                                        <h2>Response Body</h2>
                                        <SyntaxHighlighter language="json" className="web-integration-value">
                                            {JSON.stringify(responseBody, null, 2)}
                                        </SyntaxHighlighter>
                                    </section>

                                    <section>
                                        <h2>Postman Curl</h2>
                                        <SyntaxHighlighter language="json" className="web-integration-value">
                                            {postmanCurl}
                                        </SyntaxHighlighter>
                                    </section>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <div>
                {isLoading && (<Loader />)}

            </div>
            <div className='model-box'>
                <Modal show={showModel}>
                    <Modal.Header>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure, do you want deactivate this chatbot?</Modal.Body>
                    <Modal.Footer>
                        <button className='no-btn' onClick={handleClose}>
                            No
                        </button>
                        <button className='yes-btn' onClick={Deactivate}>
                            Yes
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>

            </div>
            </div>
               )}

            {!chabotOpen && (
                <div>
                    <div>
                    <button className="back-button btn-width" onClick={backChatDetails}>Back</button>
                    </div>
                    <SimpleChatPage  chatbotKey={props?.data.chatbot_key} planKey={props?.planSubscriptionKey} styleChanges={true} />
                </div>
            )}
        </>
        
    )
}

export default ChatboatDtails;
