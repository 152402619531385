import { useState } from 'react';
import classes from './ForgotPassword.module.css';
import Loader from '../../../globalShared/Loader/Loader';
import basestyle from "./../baseStyle.module.css";
import forgotIcon from '../../../../assets/ic-forget-pwd.svg';
import ApiService from '../../../../services/Api';
import { toast } from 'react-toastify';

function ForgotPassword() {
  const [isLoading, setisLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [enteredEmail, setenteredEmail] = useState('');

  //  state for userDetails
  const [form, setForm] = useState({
    email: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: null,
  });

  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case 'email':
        if (!value) errorMsg = 'Please enter Email';
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = 'Please enter a valid Email';
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(name, value, form[name === 'password' ? 'cpassword' : 'password']);
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }
  };

  const validateForm = (form) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
      const refValue = key === 'password' ? form.cpassword : key === 'cpassword' ? form.password : null;
      const msg = validateField(key, form[key], refValue);
      if (msg) errorObj[key] = msg;
    });
    return errorObj;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm(form);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const formData = {
        email: form.email
      };
      setenteredEmail(form.email);

      setisLoading(true);
      ApiService.userForgotPassword(formData)
        .then((res) => {
          setIsSuccess(true);
          setisLoading(false);
          toast.success(res?.message);
        })
        .catch((error) => {
          setisLoading(false);
          toast.error(error?.message);
        });
    }
  };

  return (
    <div className={classes["main-container"]}>
      <div className={classes["sign_up-box"]}>
        <div className={classes['forgot-logo']}>
          <img src={forgotIcon} alt=""/>
        </div>

        <div className={classes.header}>Forgot Password</div>

        {!isSuccess && (
          <div>
            <form onSubmit={handleSubmit}>
              <div className={classes["form-section"]}>
                <div>
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`${classes.label} form-label`}
                  >
                    Email
                  </label>
                  <input
                    onChange={changeHandler}
                    type="text"
                    placeholder="name@example.com"
                    name="email"
                    value={form.email}
                    onBlur={changeHandler}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  {formErrors.email && <span className={basestyle.error}>{formErrors.email}</span>}
                </div>
                <div className="mt-2">
                  <button className={classes["submit-btn"]}>Submit</button>
                </div>
              </div>
            </form>
          </div>
        )}

        {isSuccess && (
          <div className="text-center">Please check your email {enteredEmail} for the link to reset your password.</div>
        )}
      </div>
      <div>{isLoading && <Loader />}</div>
    </div>
  )
}

export default ForgotPassword;