import { RotatingLines } from "react-loader-spinner";
import './Loader.css'
function Loader() {
    return (
        <div className="Loader-aginment">
            <RotatingLines
            strokeColor="#3498db"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true} />
        </div>
    )
}
export default Loader;