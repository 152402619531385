import { useEffect, useState } from 'react';
import './PlansList.css'
import ApiService from '../../../../../services/Api';
import Plans from './Plans/Plans';
import Loader from '../../../../globalShared/Loader/Loader';

const PlansList = (props)=> {
    const [plans, setPlans] = useState([]);
    const [userPlan, setuserPlan] = useState({});
    const [isLoading, setisLoading] = useState(false);
    
const updatePlan=()=>{
    fetchPlans()
}
    const fetchPlans = async () => {
        setisLoading(true)
        await ApiService.planList().then(res => {
            setisLoading(false)
            if(!props?.selectTab && res?.userPlan){
                props?.getTabChanges(2, false) ;
                props?.viewChatboat() 
                return
            }
            setPlans(res.plans);
            setuserPlan(res.userPlan);
           
        }).catch(error => {
            setisLoading(false)
        })
    };
    useEffect(() => {
        fetchPlans();
    }, []);

    return (
        <div className="main-block-container">
            <div className="heading-block">
                <h1 className="block-1">Genxcellence Chatbot Plans.<span className="block-2"> Scale anytime</span></h1>
                {/* <h1 className="block-2">Scalable Plans</h1> */}
            </div>
            <div className="plans-and-subscriptions">
                {plans.map((item) => <Plans key={item.id} planList={item} currentUserPlan={userPlan}  updatePlanList={updatePlan} />)}
            </div>
            <div>
           {isLoading && (<Loader />)}
           </div>
        </div>
    )
}

export default PlansList;