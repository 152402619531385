import React from "react";
import "./Footer.css";
import footer from "../../../../assets/footer-logo.svg";
// import youTube from "../../../../assets/ic-youtube.svg";
import insta from "../../../../assets/ic-instagram-blue.svg";
import linkdin from "../../../../assets/ic-linkedin-blue.svg";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="main-container">
          {/* section1 */}
          <section className="flex-container">
            <div className="left-section sections">
              <div className="left-section-content">
                <div className="items">
                  {/* <span className="heading-content">
                    <h2>Genxcellence AI Services</h2>
                    </span> */}
                  <a  href="/" className="footer-logo"><img src={footer} /></a>
                </div>
                <p className="title-content">Chatbot for your website</p>
              </div>

              <div className="left-section-content">
                <div className="social-links">
                  <a href="/">
                    <button className="contact-button">Contact</button>{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/genxcellence/"
                    className="social-link linkedin-link"
                    target="_blank"
                  >
                    <img src={linkdin}  alt="LinkedIn"  />
                  </a>
                  <a
                    href="https://www.instagram.com/genxcellence_india/"
                    target="_blank"
                    className="social-link instagram-link"
                  >
                    <img src={insta} alt="Instagram"/>
                  </a>
                  {/* <a href="/" className="social-link youtube-link">
                    <img src={youTube} />
                  </a> */}
                </div>
              </div>
            </div>

            <div className="right-section sections">
              <div className="right-section-content">
                <a><Link to="/pricing">Pricing</Link></a>
                <a><Link to="/sign-in">Sign in</Link></a>
                <a><Link to="/signin">Sign up</Link></a>
                <a><Link to="/contact">Contact us</Link></a>
              </div>
              {/* <div className="right-section-content">
                <h5>Resources</h5>
                <a href="/">Contact Us</a>
                <a href="/">Guide</a>
                <a href="/">Blog</a>
              </div> */}
              {/* <div className="right-section-content">
                <h5>Gcon</h5>
                <a href="/">Privacy Policy</a>
                <a href="/">Terms of Service</a>
                <a href="/">DPA</a>
                <a href="/">Cookie Policy</a>
                <a href="/">Trust Center</a>
                <a href="/">Cookie Preferences</a>
              </div> */}
            </div>
          </section>
        </div>

        {/* section2 */}
        {/* <div className="footer-design">

        </div> */}
        <div className="copyright">
          © 2024 Genxcellence. All rights reserved.
        </div>
      </footer>
    </div>
  );
}
